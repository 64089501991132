/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { useSessionState } from '@beelineloans/cx-library/src/state/stateProvider';
import Template from '@beelineloans/cx-library/src/components/layout/TemplateV2';
import { ReferralHeader } from '@beelineloans/cx-library/src/components/layout/headers';
import { HeaderWithButtonAndVideo, HeaderWithForm } from '@beelineloans/cx-library/src/components/layout/headersv2';
import SEO from '@beelineloans/cx-library/src/components/SEO';
import { SocialProof } from '@beelineloans/cx-library/src/components/layout/panels';
import CONST from '@beelineloans/cx-library/src/utils/constants';
import { Theme } from '@beelineloans/cx-library/src/theme/default';
import { Breakpoint, Legal, Paragraph, Span } from '@beelineloans/cx-library/src/components/typography';
import SiteCopy from '@beelineloans/cx-library/src/utils/site-copy';
import StessaLogo from '@beelineloans/cx-library/src/images/logos/stessa.png';

const TemplateWrap = styled.div`
  @media only screen and (${Theme.mediaBreakpoints.tablet}) {
    #header-child-left {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
`;

// const ChatTemplate = styled(NavOnlyTemplate)`
//   transition: all 0.3s ease-in-out;

//   @media only screen and (max-width: 767px) {
//     ${(props) =>
//       props.$fixed &&
//       css`
//         position: fixed;
//         top: 0;
//         left: 0;
//         width: 100%;
//         z-index: 20;
//         box-shadow: 0 0px 16px rgba(0, 0, 76, 15%);
//       `};
//   }
// `;
const IndexPage = ({ location }) => {
  // const [isHeaderFixed, setIsHeaderFixed] = useState(false);
  const { state } = location;

  const realtorName = state ? state.realtorName : null;
  const realtorFirmName = state ? (state.realtorFirmName || '').toLowerCase() : null;

  let headlineBlock = <Breakpoint smallBreakpoint={<>Thanks! A Loan Guide will reach out soon.</>} largeBreakpoint={<>Thanks! A Loan Guide will reach out soon.</>} />;

  // let chatButtonLink;
  let adjustedBGColour;
  let templateProps;
  let headerProps;
  let celebrateProps = {
    heading: 'Get the loan out of the way and get to your happy place.',
    children: (
      <>
        <Paragraph>So where is that? </Paragraph>
        <Paragraph>In your new home, snuggling on the sofa and finding your new local coffee hangout? </Paragraph>
        <Paragraph>Breathing a sigh of relief after an easy cash-out refi frees you up?</Paragraph>
        <Paragraph>Kicking back watching your portfolio expand on a Tuesday morning while you cheers yourself at the movie theater?</Paragraph>
      </>
    )
  };

  if (realtorName) {
    // TODO: all this should be stored in with the realtor details, not hard coded

    if (realtorFirmName === 'awning') {
      // chatButtonLink = CONST.LINKS.EXTERNAL.CALENDLY;
    } else if (realtorFirmName === 'stessa') {
      headlineBlock = 'Get a home loan from your sofa.';
      adjustedBGColour = {
        backgroundColor: Theme.colours.background.tanLight
      };
      templateProps = {
        headerComponent: ReferralHeader
      };
      headerProps = {
        referrerLogo: StessaLogo
      };
      celebrateProps = {
        heading: 'Can we get a ‘hell yeah&apos; for financial freedom?',
        children: (
          <>
            <Paragraph>
              There&apos;s nothing like the feeling of taking steps towards financial freedom — and we get it. We know the loan itself is less important than taking you where you
              want to be.
            </Paragraph>
            <Paragraph>And where&apos;s that?</Paragraph>
            <Paragraph>Kicking back watching your portfolio expand on a Tuesday morning while you cheers yourself at the cinema.</Paragraph>
            <Paragraph>So, let&apos;s get the loan out of the way — so you can watch that rent roll in.</Paragraph>
          </>
        )
      };
    } else if (realtorFirmName === 'J. Trainor & Company' || realtorFirmName === 'Halo') {
      // keep defaults
    }
  }

  return (
    <TemplateWrap>
      {/* <ChatTemplate sideNavLinksComponent={false} {...templateProps} headerProps={templateOptions} $fixed={isHeaderFixed} $realtorFirmName={realtorFirmName}> */}
      <Template
        sideNavLinksComponent={false}
        headline={headlineBlock}
        $realtorFirmName={realtorFirmName}
        headerComponent={HeaderWithForm}
        {...templateProps}
        headerProps={{
          options: { headerRatings: true, voomly: true },
          bobContactThankYou: true,
          ...headerProps
        }}
      >
        <SEO path={CONST.LINKS.MAIN.BOB.THANK_YOU} title="Thanks! A Loan Guide will reach out soon." description="Thanks! A Loan Guide will reach out soon." antiFlicker />
        {/* <ChatHeader onHeaderFixed={setIsHeaderFixed} /> */}
        <SocialProof dark />
      </Template>
    </TemplateWrap>
  );
};

IndexPage.propTypes = {
  location: PropTypes.object,
  referral: PropTypes.bool
};

IndexPage.defaultProps = {
  referral: false
};

export default IndexPage;
